<template>
  <v-app id="inspire">
    <v-container fluid>
      <v-row>
        <v-col v-if="showHeader" class="col-12">
          <top-nav />
        </v-col>
      </v-row>
    </v-container>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TopNav from '@/components/Navigation/TopNav.vue';

export default {
  name: 'App',
  components: {
    TopNav,
  },
  computed: {
    showHeader() {
      let showHeader = true;
      if (this.$route.name === 'Login') showHeader = false;
      if (this.$route.name === 'RequestPassword') showHeader = false;
      return showHeader;
    },
  },
};
</script>

<style lang="scss">
h1 {
  font-size: 26px;
  font-weight: normal;
}

.main-navigation {
  width: 100%;
  li {
    float:left;
    list-style-type: none;
    padding: 5px 40px 15px 0;
    text-align: center;
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &.active {
      background-color: #EEE;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
  }
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}
.sub-navigation {
  position: relative;
  top: -8px;
  width: 100%;
  background-color: #EEE;
  li {
    float:left;
    list-style-type: none;
    padding: 5px 25px 5px 10px;
    background-color: #EEE;
    cursor: pointer;

    &.one-of { width: 100% }
    &.two-of { width: calc(100% / 2) }
    &.three-of { width: calc(100% / 3) }
    &.four-of { width: calc(100% / 4) }
    &.five-of { width: calc(100% / 5) }
    &.six-of { width: calc(100% / 6) }
    &.seven-of { width: calc(100% / 7) }
    &.eight-of { width: calc(100% / 8) }
    &.nine-of { width: calc(100% / 9) }
    &.ten-of {
      width: calc(100% / 10);
      font-size: 12px;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &.active {
      text-decoration: underline;
    }
  }
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}
img {
  max-width: 100%;
}
.modal {
  position: fixed;
  top: 20%;
  right: 15%;
  bottom: 5%;
  left: 15%;
  z-index: 99;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  padding: 20px;
  overflow: scroll;

  .close {
    position: absolute!important;
    top: 3px;
    right: 3px;
  }
}

.devnotes {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 60%;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #F0F0F0;
  padding: 20px;
  border-radius: 5px;
  color: tomato;
  font-style: italic;

  .close {
    position: absolute!important;
    top: 3px;
    right: 3px;
  }
}
.preview-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #FFF;
  overflow: scroll;
}

.preview-box-inner {
  width: 210mm;
  margin: 0 auto;

  &.landscape {
    width: 297mm;
  }
}

@media print {
  .preview-box {
    position: static;
    overflow: hidden;
  }
}

.close-preview {
  position: absolute!important;
  top: 5px;
  right: 5px;
}

.print-preview {
  position: absolute!important;
  top: 5px;
  right: 40px;
}

.pdf-preview {
  position: absolute!important;
  top: 5px;
  right: 75px;
}

.preview-inner {
  margin: 1mm 6mm;
}

@media print {
  .preview-inner {
    page-break-after: always;
  }
}

.border-top {
  border-top: 1px solid #333;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.bg-orange {
  background-color: #fff5eb;
}

.bg-white {
  background-color: #fff;
}

.text-heavy {
  font-weight: bold;
}

.text-red {
  color: tomato;
}
.text-green {
  color: seagreen;
}
.text-light-grey input {
  color: #9D9D9D !important;
}
.text-amber {
  color: #FFBF00;
}

.pr-20 {
  padding-right: 80px;
}

.address-corner {
  position: relative;
  padding-top: 20px;
  padding-left: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 8px;
    background-color: #f88d2b;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 30px;
    background-color: #f88d2b;
  }
}
.large-heading {
  font-size: 40px;
  line-height: 1;
}
.text-orange {
  color: #f88d2b;
}
.text-heavy {
  font-weight: bold;
}
.bold-price {
  font-weight: bold;
  font-size: 30px;
}
.numbered-para {
  position: relative;
  padding-left: 20px;
  &::before {
    content: attr(data-number);
    position: absolute;
    top: 0;
    left: -15px;
    color: #f88d2b;
    font-weight: bold;
  }
}
.link {
  cursor: pointer;
}
.sterling-input {
  position: relative;
  &::before {
    content: '£';
    position: absolute;
    left: -15px;
    top: 17px;
  }
}
.boxed-list {
  position: absolute!important;
  top: 0!important;
  left: 0!important;
  right: -100px!important;
  padding: 5px!important;
  margin-top: -15px;
  max-height: 250px;
  overflow-y: scroll;
  border: 1px solid #EEE!important;
}
.relative {
  position: relative;
}
.alternate-rows:nth-child(odd) {
    background-color: #fbfb99;
}
.grid-lines th, .grid-lines td {
  border: 1px solid #CCC;
}
.input-container input {
  width: 75px
}
.td-tooltip {
  position: relative;
  &:before {
    content: attr(data-tooltip);
    position: absolute;
    right: 100%;
    display: none;
    padding: 5px;
    border-radius: 5px;
    background-color: #CCC;
    width: 95%;
    text-align: center;
  }
  &:hover:before {
    display: inline-block;
  }
}
.export-csv-wrap {
  display: inline-block;
}
.text-tiny {
  font-size: 10px;
}
.text-small {
  font-size: 12px;
}
.text-mid {
  font-size: 14px;
}

.bordered {
  border: 1px solid #333;

  &.no-right {
    border-right: none;
  }
}
.border-left {
  border-left: 1px solid #333;
}
.border-right {
  border-right: 1px solid #333;
}
.border-top {
  border-top: 1px solid #333;
}
.border-collapse {
  border-collapse: collapse;
}
.invoice-lines-height {
  min-height: 300mm;
}
.currency-input {
  position: relative;
  padding-left:9px;
  &:before {
    content: '£';
    position: absolute;
    left: 0;
  }
}
.highlight {
  display: inline-block;
  background-color: yellow;
}
.job-card-table {
  width: 100%;
  border-collapse: collapse;
}
.boq-table {
  width: 100%;
  border-collapse: collapse;
}
.job-card-table tr th,
.job-card-table tr td,
.boq-table tr th,
.boq-table tr td {
  padding: 3px;
}
.job-card-table tr td {
  white-space: normal;
}
.job-card-table,
.job-card-table table,
.job-card-table tr td,
.job-card-table tr th,
.job-card-table tr {
  background-color: #FFF;
  border: none;
}
.cell-border-top {
  border-top: 1px solid #333!important;
}
.cell-border-right {
  border-right: 1px solid #333!important;
}
.cell-border-bottom,
.border-bottom {
  border-bottom: 1px solid #333!important;
}
.cell-border-left {
  border-left: 1px solid #333!important;
}
.cell-fill-yellow {
  background-color: yellow!important;
}
.no-pad-cell {
  padding: 0!important;
}
.text-red {
  color: tomato!important;
}
.small-image {
  width: 150px;
}
@media print {
  .job-card-table {
    font-size: 11px;
  }
}

.unspaced-p p {
  padding-bottom: 0!important;
  margin-bottom: 0!important;
  display: inline;
}

th {
  padding: 0 16px;
}

.clear-both {
  clear:both;
}

.inline {
  display: inline;
}

.lh-1-3 {
  line-height: 1.3;
}

.text-orange {
  color: #ff9800!important;
}

.bg-white {
  background-color: #F0F0F0;
}

.inset-table {
  width: 100%;
  border-collapse: collapse;
}

.padded-table {
  tr th,
  tr td {
    padding: 3px;
  }
}

.table-small-text {
  tr th, tr td {
    font-size: 14px;
  }
}

.box-row {
  margin: 3px;
  border: 3px solid #ccc;
}

.compact-table {
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
  tr {
    width: 100%;
  }
  tr td {
    line-height: 1.2;
    width: 50%;
  }
}

.text-bold {
  font-weight: bold;
}

.quillWrapper {
  max-height: 150px;
  overflow-y: scroll;
}

.planning-navigation-box {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 99;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #F0F0F0;
  padding-left: 5px;
  box-shadow: 3px 3px 5px grey;
}

.planning-side-nav {
  height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
}

</style>
