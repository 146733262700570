<template>
    <ul class="sub-navigation pl-0">
      <router-link
        custom v-slot="{ navigate }"
        to="/proformas/awaiting-index">
        <li
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
          :class="{ active : isProformasAwaitingIndex }">To be raised</li>
      </router-link>
      <router-link
        custom v-slot="{ navigate }"
        to="/proformas/issued-index">
        <li
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
          :class="{ active : isProformasIssuedIndex }">Raised</li>
      </router-link>
    </ul>
  </template>
  
  <script>
  export default {
    name: 'QuotationsNav',
    computed: {
      isProformasAwaitingIndex() {
        return this.$route.name === 'ProformasAwaitingIndex';
      },
      isProformasIssuedIndex() {
        return this.$route.name === 'ProformasIssuedIndex';
      },
    },
  };
  </script>
  