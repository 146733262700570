<template>
  <ul class="sub-navigation pl-0">
    <router-link
      custom v-slot="{ navigate }"
      to="/live-jobs/index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="five-of"
        :class="{ active : isLiveJobsIndex }">All Jobs</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/live-jobs/not-planned">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="five-of"
        :class="{ active : isLiveJobsNotPlanned }">Jobs Not Planned</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/live-jobs/planned">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="five-of"
        :class="{ active : isLiveJobsPlanned }">Planned Jobs</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/live-jobs/not-approved">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="five-of"
        :class="{ active : isLiveJobsNotApproved }">Complete not approved</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/live-jobs/awaiting-accounts">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="five-of"
        :class="{ active : isLiveJobsNotInvoiced }">Awaiting Accounts</li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'QuotationsNav',
  computed: {
    isLiveJobsIndex() {
      return this.$route.name === 'LiveJobsIndex';
    },
    isLiveJobsPlanned() {
      return this.$route.name === 'LiveJobsPlanned';
    },
    isLiveJobsNotPlanned() {
      return this.$route.name === 'LiveJobsNotPlanned';
    },
    isLiveJobsNotApproved() {
      return this.$route.name === 'LiveJobsNotApproved';
    },
    isLiveJobsNotInvoiced() {
      return this.$route.name === 'LiveJobsNotInvoiced';
    },
  },
};
</script>
