<template>
  <ul class="sub-navigation pl-0">
    <router-link
      custom v-slot="{ navigate }"
      to="/reports/index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isReportsIndex }">All</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/reports/weekly-pay">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isReportsWeeklyPay }">Weekly Pay</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/reports/jobs-financial-overview-index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isReportsWeeklyPay }">Jobs</li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'DailyReturnSheetsNav',
  computed: {
    isReportsIndex() {
      return this.$route.name === 'ReportsIndex';
    },
    isReportsWeeklyPay() {
      return this.$route.name === 'ReportsWeeklyPay';
    },
  },
};
</script>
