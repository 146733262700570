<template>
  <ul class="sub-navigation pl-0">
    <router-link custom v-slot="{ navigate }" to="/customers/index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="four-of"
        :class="{ active : isCustomersIndex }">
        All Customers
      </li>
    </router-link>
    <router-link custom v-slot="{ navigate }" to="/customers/create">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="four-of"
        :class="{ active : isCustomersCreate }">
        New Customer
    </li>
    </router-link>
    <router-link custom v-slot="{ navigate }" to="/customers/search">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="four-of"
        :class="{ active : isCustomersSearch }">
        Search Customers
      </li>
    </router-link>
    <router-link custom v-slot="{ navigate }" to="/customers/statement">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="four-of"
        :class="{ active : isCustomersStatement }">
        Statements
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'CustomersNav',
  computed: {
    isCustomersIndex() { return this.$route.name === 'CustomersIndex'; },
    isCustomersCreate() { return this.$route.name === 'CustomersCreate'; },
    isCustomersSearch() { return this.$route.name === 'CustomersSearch'; },
    isCustomersStatement() { return this.$route.name === 'CustomersStatement'; },
  },
};
</script>
