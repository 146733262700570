var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"sub-navigation pl-0"},[_c('router-link',{attrs:{"custom":"","to":"/admin/boqs/index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isBoqs },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("BOQs")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/colours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isColours },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Colours")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/materials"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isMaterials },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Materials")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/surface-types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isMaterials },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Surface Types")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/work-types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isMaterials },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Work Types")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/personnel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [(_vm.userPermissions.includes(8))?_c('li',{staticClass:"eight-of",class:{ active : _vm.isCrew },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Personnel")]):_vm._e()]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/costs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isCosts },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Costs")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/fuel/index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isFuelDeliveries },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Fuel")])]}}])})],1),_c('hr'),_c('ul',{staticClass:"sub-navigation pl-0"},[_c('router-link',{attrs:{"custom":"","to":"/admin/permissions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isUsers },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Permissions")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isUsers },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Users")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/boq-bonus-rates/index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isUsers },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("BOQ Bonus")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/business-types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isUsers },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Business Types")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/admin/authorisers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"eight-of",class:{ active : _vm.isUsers },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Authorisers")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }