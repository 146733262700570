import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '@/views/Login.vue';
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/two-factor/:userid',
    name: 'TwoFactor',
    component: () => import('../views/TwoFactor.vue'),
  },
  {
    path: '/request-password',
    name: 'RequestPassword',
    component: () => import('../views/auth/RequestPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:userid',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('../views/TermsConditions.vue'),
  },
  {
    path: '/po-terms-and-conditions',
    name: 'PoTermsAndConditions',
    component: () => import('../views/PoTermsConditions.vue'),
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('../views/Start.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/customers/Customers.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'CustomersIndex',
        component: () => import('../views/customers/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'edit/:id',
        name: 'CustomersEdit',
        component: () => import('../views/customers/Edit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'search',
        name: 'CustomersSearch',
        component: () => import('../views/customers/Search.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'statement',
        name: 'CustomersStatement',
        component: () => import('../views/customers/Statement.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'statement-print',
        name: 'CustomersStatementPrint',
        component: () => import('../views/customers/StatementPrint.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'create',
        name: 'CustomersCreate',
        component: () => import('../views/customers/Create.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-contact/:id',
        name: 'CustomersAddContact',
        component: () => import('../views/customers/AddContact.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'payment/:id',
        name: 'CustomersPayment',
        component: () => import('../views/customers/Payment.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view/:id',
        name: 'CustomersView',
        component: () => import('../views/customers/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/planning',
    name: 'Planning',
    component: () => import('../views/planningBoard/PlanningBoard.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'help',
        name: 'PlanningBoardHelp',
        component: () => import('../views/planningBoard/Help.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'plan',
        name: 'PlanningBoardPlan',
        component: () => import('../views/planningBoard/Plan.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'data-tasks',
        name: 'PlanningBoardDataTasks',
        component: () => import('../views/planningBoard/DataTasks.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'holidays',
        name: 'PlanningBoardHolidays',
        component: () => import('../views/planningBoard/Holidays.vue'),
        beforeEnter(to, from, next) {
          console.log(store.state.userid);
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'map',
        name: 'Map',
        component: () => import('../views/planningBoard/Map.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'order-vehicles',
        name: 'Vehicles',
        component: () => import('../views/planningBoard/OrderVehicles.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'vehicles',
        name: 'PlanningBoardVehicles',
        component: () => import('../views/planningBoard/Vehicles.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/quotations',
    name: 'Quotations',
    component: () => import('../views/quotations/Quotations.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'QuotationsIndex',
        component: () => import('../views/quotations/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'expired',
        name: 'QuotationsExpired',
        component: () => import('../views/quotations/Expired.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'all-open',
        name: 'QuotationsAllOpen',
        component: () => import('../views/quotations/AllOpen.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'my-open',
        name: 'QuotationsMyOpen',
        component: () => import('../views/quotations/MyOpen.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view/:id',
        name: 'QuotationsView',
        component: () => import('../views/quotations/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'preview/:id',
        name: 'QuotationsPreview',
        component: () => import('../views/quotations/Preview.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-documents/:id',
        name: 'QuotationsAddDocuments',
        component: () => import('../views/quotations/AddDocuments.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'update/:id',
        name: 'QuotationsUpdate',
        component: () => import('../views/quotations/Update.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'search',
        name: 'QuotationsSearch',
        component: () => import('../views/quotations/Search.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'create/:customer?',
        name: 'QuotationsCreate',
        component: () => import('../views/quotations/Create.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-lines/:quotation',
        name: 'QuotationsAddLines',
        component: () => import('../views/quotations/AddLines.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'convert/:id',
        name: 'QuotationsConvert',
        component: () => import('../views/quotations/Convert.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'job-lost/:id',
        name: 'QuotationLost',
        component: () => import('../views/quotations/JobLost.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],

  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/reports/Reports.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'ReportsIndex',
        component: () => import('../views/reports/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'weekly-pay',
        name: 'ReportsWeeklyPay',
        component: () => import('../views/reports/WeeklyPay.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'login-attempts',
        name: 'LoginAttempts',
        component: () => import('../views/reports/LoginAttempts.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'quotes-converted',
        name: 'ReportsQuotesConverted',
        component: () => import('../views/reports/QuotesConverted.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'jobs-financial-overview-index',
        name: 'ReportsJobsFinancialOverviewIndex',
        component: () => import('../views/reports/JobsFinancialOverviewIndex.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'job-financial-overview/:id',
        name: 'ReportsJobsFinancialOverview',
        component: () => import('../views/reports/JobFinancialOverview.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'invoiced-totals-by-date',
        name: 'ReportsInvoicedTotalsByDate',
        component: () => import('../views/reports/InvoicedTotalsByDate.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'paid-application',
        name: 'PaidApplicationReport',
        component: () => import('../views/reports/PaidApplication.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'sage-commission',
        name: 'SageCommissionReport',
        component: () => import('../views/reports/SageCommission.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'sales-statistics-summary',
        name: 'SalesStatisticsSummary',
        component: () => import('../views/reports/SalesStatisticsSummary.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'drs-job-type',
        name: 'DrsJobType',
        component: () => import('../views/reports/DrsJobTypeReport.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'aged-debt',
        name: 'AgedDebt',
        component: () => import('../views/reports/AgedDebt.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'job-pl/index',
        name: 'JobPLIndex',
        component: () => import('../views/reports/jobPl/index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'job-pl/pl/:id',
        name: 'JobPLIndexView',
        component: () => import('../views/reports/jobPl/Pl.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'retentions',
        name: 'Retentions',
        component: () => import('../views/reports/Retentions.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'sales-commission',
        name: 'SalesCommission',
        component: () => import('../views/reports/SalesCommission.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'working-time-directive',
        name: 'WorkingTimeDirective',
        component: () => import('../views/reports/WorkingTimeDirective.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'purchasing',
        name: 'Purchasing',
        component: () => import('../views/reports/Purchasing.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ]
  },
  {
    path: '/live-jobs',
    name: 'LiveJobs',
    component: () => import('../views/livejobs/Livejobs.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'LiveJobsIndex',
        component: () => import('../views/livejobs/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'update/:id',
        name: 'LiveJobsUpdate',
        component: () => import('../views/livejobs/Update.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-lines/:id',
        name: 'LiveJobsAddLines',
        component: () => import('../views/livejobs/AddLines.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-documents/:id',
        name: 'LiveJobsAddDocuments',
        component: () => import('../views/livejobs/AddDocuments.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'planned',
        name: 'LiveJobsPlanned',
        component: () => import('../views/livejobs/Planned.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'not-planned',
        name: 'LiveJobsNotPlanned',
        component: () => import('../views/livejobs/NotPlanned.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'not-approved',
        name: 'LiveJobsNotApproved',
        component: () => import('../views/livejobs/NotApproved.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'awaiting-accounts',
        name: 'LiveJobsAwaitingAccounts',
        component: () => import('../views/livejobs/AwaitingAccounts.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view/:id',
        name: 'LiveJobsPreview',
        component: () => import('../views/livejobs/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'job-card-print/:id',
        name: 'LiveJobsCardPrint',
        component: () => import('../views/livejobs/JobCardPrint.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'overview/:id',
        name: 'LiveJobsOverview',
        component: () => import('../views/livejobs/Overview.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'progress/:id',
        name: 'LiveJobsProgress',
        component: () => import('../views/livejobs/Progress.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('../views/invoices/Invoices.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'InvoicesIndex',
        component: () => import('../views/invoices/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'index-draft',
        name: 'InvoicesIndexDraft',
        component: () => import('../views/invoices/IndexDraft.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'index-applications',
        name: 'InvoicesIndexApplication',
        component: () => import('../views/invoices/IndexApplication.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add',
        name: 'InvoicesAdd',
        component: () => import('../views/invoices/Add.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-lines/:id',
        name: 'InvoicesAddLines',
        component: () => import('../views/invoices/AddLines.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view-draft/:id',
        name: 'InvoicesViewDraft',
        component: () => import('../views/invoices/ViewDraft.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view/:id',
        name: 'InvoicesView',
        component: () => import('../views/invoices/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'payment-history/:id',
        name: 'InvoicesPaymentHistory',
        component: () => import('../views/invoices/PaymentHistory.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'credit-note/:id',
        name: 'InvoicesRaiseCreditNote',
        component: () => import('../views/invoices/Credit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'credit-notes',
        name: 'InvoicesCreditNotes',
        component: () => import('../views/invoices/CreditNotes.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view-credit-note/:id',
        name: 'InvoicesCreditNoteView',
        component: () => import('../views/invoices/CreditNoteView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'credit-note-print-view/:id',
        name: 'InvoicesCreditNotePrintView',
        component: () => import('../views/invoices/CreditNotePrintView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-consolidated-payment',
        name: 'InvoicesAddConsolidatedPayment',
        component: () => import('../views/invoices/AddConsolidatedPayment.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'draft-consolidated-payment/:id',
        name: 'InvoicesDraftConsolidatedPayment',
        component: () => import('../views/invoices/DraftConsolidatedPayment.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'consolidated-payment-print-view/:id',
        name: 'InvoicesConsolidatedPaymentPrintView',
        component: () => import('../views/invoices/ConsolidatedPaymentPrintView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'consolidated-payments',
        name: 'InvoicesConsolidatedPayments',
        component: () => import('../views/invoices/ConsolidatedPayments.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'print-view/:id',
        name: 'InvoicesPrintView',
        component: () => import('../views/invoices/PrintView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'search',
        name: 'InvoicesSearch',
        component: () => import('../views/invoices/Search.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'statement/:id',
        name: 'InvoicesStatement',
        component: () => import('../views/invoices/Statement.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'statement-print/:id/:date1?/:date2?',
        name: 'InvoicesStatementPrint',
        component: () => import('../views/invoices/StatementPrint.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/purchasing',
    name: 'PurchasingView',
    component: () => import('../views/purchasing/Purchasing.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'PurchasingIndex',
        component: () => import('../views/purchasing/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'awaiting-authorisation',
        name: 'PurchasingAwaitingAuthorisation',
        component: () => import('../views/purchasing/AwaitingAuthorisation.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'create-po/:id?',
        name: 'CreatePo',
        component: () => import('../views/purchasing/CreatePo.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'suppliers',
        name: 'PurchasingSuppliers',
        component: () => import('../views/purchasing/Suppliers.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'suppliers-add',
        name: 'PurchasingSupplierAdd',
        component: () => import('../views/purchasing/SupplierAdd.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'suppliers-edit/:id',
        name: 'PurchasingSupplierEdit',
        component: () => import('../views/purchasing/SupplierEdit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'suppliers-view/:id',
        name: 'PurchasingSupplierView',
        component: () => import('../views/purchasing/SupplierView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'supplier-categories',
        name: 'PurchasingSupplierCategories',
        component: () => import('../views/purchasing/SupplierCategories.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-supplier-category',
        name: 'PurchasingAddSupplierCategorie',
        component: () => import('../views/purchasing/AddSupplierCategory.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'edit-supplier-category/:id',
        name: 'PurchasingEditSupplierCategorie',
        component: () => import('../views/purchasing/EditSupplierCategory.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'purchase-orders-view/:id',
        name: 'PurchaseOrdersView',
        component: () => import('../views/purchasing/PurchaseOrderView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'purchase-orders-print-view/:id',
        name: 'PurchaseOrdersPrintView',
        component: () => import('../views/purchasing/PurchaseOrderPrintView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'AdminIndex',
        component: () => import('../views/admin/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boqs/index',
        name: 'AdminBoqIndex',
        component: () => import('../views/admin/Boqs/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boqs/add',
        name: 'AdminBoqAdd',
        component: () => import('../views/admin/Boqs/Add.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boqs/edit/:id',
        name: 'AdminBoqEdit',
        component: () => import('../views/admin/Boqs/Edit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boqs/update-prices',
        name: 'AdminBoqUpdatePrices',
        component: () => import('../views/admin/Boqs/UpdatePrices.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boqs/view-changes',
        name: 'AdminBoqViewChanges',
        component: () => import('../views/admin/Boqs/ViewChanges.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boq-bonus-rates/index',
        name: 'AdminBoqBonusRatesIndex',
        component: () => import('../views/admin/BoqBonusRates/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boq-bonus-rates/add',
        name: 'AdminBoqBonusRatesAdd',
        component: () => import('../views/admin/BoqBonusRates/Add.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'boq-bonus-rates/edit/:id',
        name: 'AdminBoqBonusRatesEdit',
        component: () => import('../views/admin/BoqBonusRates/Edit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'authorisers',
        name: 'AdminAuthorisers',
        component: () => import('../views/admin/Permissions/Authorisers.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'business-types',
        name: 'AdminBusinessTypes',
        component: () => import('../views/admin/BusinessTypes/Manage.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'colours',
        name: 'AdminColours',
        component: () => import('../views/admin/Colours/Manage.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'costs',
        name: 'AdminCosts',
        component: () => import('../views/admin/Costs/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'personnel',
        name: 'AdminPersonnelIndex',
        component: () => import('../views/admin/Personnel/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'personnel-archive-index',
        name: 'AdminPersonnelArchiveIndex',
        component: () => import('../views/admin/Personnel/ArchiveIndex.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'personnel-search',
        name: 'AdminPersonnelSearch',
        component: () => import('../views/admin/Personnel/Search.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'personnel-add',
        name: 'AdminPersonnelAdd',
        component: () => import('../views/admin/Personnel/Add.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'personnel-edit/:id',
        name: 'AdminPersonnelEdit',
        component: () => import('../views/admin/Personnel/Edit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'personnel-view/:id',
        name: 'AdminPersonnelView',
        component: () => import('../views/admin/Personnel/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'personnel-planning',
        name: 'AdminPersonnelPlanning',
        component: () => import('../views/admin/Personnel/PlanningBoard.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'fuel/deliveries',
        name: 'AdminFuelDeliveries',
        component: () => import('../views/admin/Fuel/Deliveries.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'fuel/index',
        name: 'AdminFuelIndex',
        component: () => import('../views/admin/Fuel/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'materials',
        name: 'AdminMaterials',
        component: () => import('../views/admin/Materials/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'surface-types',
        name: 'AdminSurfaceTypes',
        component: () => import('../views/admin/SurfaceTypes/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'work-types',
        name: 'AdminWorkTypes',
        component: () => import('../views/admin/WorkTypes/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'users',
        name: 'AdminUsersIndex',
        component: () => import('../views/admin/Users/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'user-create',
        name: 'AdminUsersCreate',
        component: () => import('../views/admin/Users/Create.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'user-edit/:id',
        name: 'AdminUsersEdit',
        component: () => import('../views/admin/Users/Edit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'user-view/:id',
        name: 'AdminUsersView',
        component: () => import('../views/admin/Users/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'permissions',
        name: 'AdminPermissions',
        component: () => import('../views/admin/Permissions/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/contacts/Contact.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'edit/:id',
        name: 'ContactsEdit',
        component: () => import('../views/contacts/Edit.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/proformas',
    name: 'Proformas',
    component: () => import('../views/proformas/Proformas.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'awaiting-index',
        name: 'ProformasAwaitingIndex',
        component: () => import('../views/proformas/AwaitingIndex.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'issued-index',
        name: 'ProformasIssuedIndex',
        component: () => import('../views/proformas/IssuedIndex.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'progress/:id',
        name: 'ProformasProgress',
        component: () => import('../views/proformas/Progress.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view-draft/:id',
        name: 'ProformasViewDraft',
        component: () => import('../views/proformas/ViewDraft.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'print-view/:id',
        name: 'ProformasPrintView',
        component: () => import('../views/proformas/PrintView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/drs',
    name: 'Drs',
    component: () => import('../views/drs/Drs.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'DrsIndex',
        component: () => import('../views/drs/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'create',
        name: 'DrsCreate',
        component: () => import('../views/drs/Create.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'create-simple',
        name: 'DrsCreateSimple',
        component: () => import('../views/drs/CreateSimple.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'update/:id',
        name: 'DrsUpdate',
        component: () => import('../views/drs/Update.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view/:id',
        name: 'DrsView',
        component: () => import('../views/drs/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'preview/:id',
        name: 'DrsPreView',
        component: () => import('../views/drs/Preview.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'addHours/:id',
        name: 'DrsAddHours',
        component: () => import('../views/drs/AddHours.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  next();
});

router.afterEach(() => {
  goTo(0, { duration: 0 });
});

export default router;
