<template>
  <ul class="sub-navigation pl-0">
    <router-link
      custom v-slot="{ navigate }"
      to="/purchasing/index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isPurchasingIndex }">Purchase Orders</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/purchasing/awaiting-authorisation">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isPurchasingAwaitingAuth }">Awaiting Auth</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/purchasing/create-po">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isPurchasingSuppliers }">New PO</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/purchasing/suppliers">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isPurchasingSuppliers }">Suppliers</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/purchasing/suppliers-add">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isPurchasingSupplierAdd }">New Supplier</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/purchasing/supplier-categories">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="six-of"
        :class="{ active : isPurchasingSupplierCategories }">Supplier Categories</li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'PurchasingNav',
  computed: {
    isPurchasingIndex() {
      return this.$route.name === 'PurchasingIndex';
    },
    isPurchasingAwaitingAuth() {
      return this.$route.name === 'PurchasingAwaitingAuthorisation';
    },
    isPurchasingSuppliers() {
      return this.$route.name === 'PurchasingSuppliers';
    },
    isPurchasingSupplierAdd() {
      return this.$route.name === 'PurchasingSupplierAdd';
    },
    isPurchasingSupplierCategories() {
      return this.$route.name === 'PurchasingSupplierCategories';
    },
  },
};
</script>
