<template>
  <ul class="sub-navigation pl-0">
    <router-link
      custom v-slot="{ navigate }"
      to="/invoices/index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        :class="{ active : isInvoicesIndex }">All Invoices</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/invoices/index-draft">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        :class="{ active : isInvoicesIndex }">Draft Invoices</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/invoices/index-applications">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        :class="{ active : isInvoicesIndex }">Applications</li>
    </router-link>
    <router-link
      v-if="userPermissions.includes(4)"
      custom v-slot="{ navigate }"
      to="/invoices/add">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        :class="{ active : isInvoicesIndex }">Add Invoice</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/invoices/search">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        :class="{ active : isSearch }">Search Invoices</li>
    </router-link>
    <router-link
      v-if="userPermissions.includes(4)"
      custom v-slot="{ navigate }"
      to="/invoices/credit-notes">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        :class="{ active : isCreditNote }">Credit Notes</li>
    </router-link>
    <router-link
      v-if="userPermissions.includes(4)"
      custom v-slot="{ navigate }"
      to="/invoices/consolidated-payments">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        :class="{ active : isCreditNote }">Consolidated Payments</li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'QuotationsNav',
  computed: {
    isInvoicesIndex() {
      return this.$route.name === 'InvoicesIndex';
    },
    isSearch() {
      return this.$route.name === 'InvoicesSearch';
    },
    isCreditNote() {
      return this.$route.name === 'InvoicesCreditNotes';
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
};
</script>
