var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"sub-navigation pl-0"},[_c('router-link',{attrs:{"custom":"","to":"/customers/index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"four-of",class:{ active : _vm.isCustomersIndex },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" All Customers ")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/customers/create"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"four-of",class:{ active : _vm.isCustomersCreate },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" New Customer ")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/customers/search"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"four-of",class:{ active : _vm.isCustomersSearch },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" Search Customers ")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/customers/statement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{staticClass:"four-of",class:{ active : _vm.isCustomersStatement },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" Statements ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }