var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"sub-navigation pl-0"},[_c('router-link',{attrs:{"custom":"","to":"/invoices/index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:{ active : _vm.isInvoicesIndex },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("All Invoices")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/invoices/index-draft"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:{ active : _vm.isInvoicesIndex },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Draft Invoices")])]}}])}),_c('router-link',{attrs:{"custom":"","to":"/invoices/index-applications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:{ active : _vm.isInvoicesIndex },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Applications")])]}}])}),(_vm.userPermissions.includes(4))?_c('router-link',{attrs:{"custom":"","to":"/invoices/add"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:{ active : _vm.isInvoicesIndex },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Add Invoice")])]}}],null,false,501411977)}):_vm._e(),_c('router-link',{attrs:{"custom":"","to":"/invoices/search"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:{ active : _vm.isSearch },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Search Invoices")])]}}])}),(_vm.userPermissions.includes(4))?_c('router-link',{attrs:{"custom":"","to":"/invoices/credit-notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:{ active : _vm.isCreditNote },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Credit Notes")])]}}],null,false,604031126)}):_vm._e(),(_vm.userPermissions.includes(4))?_c('router-link',{attrs:{"custom":"","to":"/invoices/consolidated-payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:{ active : _vm.isCreditNote },attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Consolidated Payments")])]}}],null,false,425230842)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }